*,
*::after,
*::before {
	box-sizing: inheritance;
	margin: 0;
	padding: 0;
}

/* 
 * 0 - 600      : phone
 * 600 - 900    : tablet portrait
 * 900 - 1024   : tablet landscape
 * 1024 - 1440  : laptop-large
 * 1440 - 1800  : desktop (normal)
 * 1800+        : big desktop
 */
html {
	font-size: 62.5%;
}
@media (max-width: 90em) {
	html {
		font-size: 65%;
	}
}
@media (max-width: 64em) {
	html {
		font-size: 62.5%;
	}
}
@media (max-width: 37.5em) {
	html {
		font-size: 55%;
	}
}
@media (max-width: 26.5625em) {
	html {
		font-size: 50%;
	}
}
@media (min-width: 112.5em) {
	html {
		font-size: 75%;
	}
}
@media (min-width: 150em) {
	html {
		font-size: 100%;
	}
}

body {
	box-sizing: border-box;
	font-family: HKGrotesk, Arial, sans-serif;
	font-size: 1.6rem;
}

@font-face {
	font-family: 'HKGrotesk';
	font-style: normal;
	font-weight: 300;
	src: url('./fonts/HKGrotesk-LightLegacy.otf') format('opentype');
}

@font-face {
	font-family: 'HKGrotesk';
	font-style: bold;
	font-weight: 600;
	src: url('./fonts/HKGrotesk-Bold.otf') format('opentype');
}
